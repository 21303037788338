import { inject } from 'vue';
import {
  type ShowNotification,
  showNotificationInjectKey,
} from '~/shared/composables/notification/use-notification-manager';

export function useNotification() {
  const showNotification = inject<ShowNotification>(showNotificationInjectKey);

  return { showNotification };
}
